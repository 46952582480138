import React, { useLayoutEffect, useState, useCallback } from "react";
import _ from "lodash";
import { airportConfig, mlookConfig } from "../config/appConfig";
import { get_airport_translation, get_mlook_translation, get_room } from "../api/helper";
import { getEventId, getRoomId, getIsDemo } from "./../utils/QueryString";
import { isNumber, isNullOrUndefined } from "../utils/Validator";
import { loadCss, setCssVariables } from "../utils/Tools";
import { getVmBaseUrl } from "../utils/Helper";
import VmSocketContainer from "./../components/vmSocket/VmSocketContainer";


const SignageContext = React.createContext({
  appConfig: null,
});

export const SignageContextProvider = (props) => {
  const [appConfig, setAppConfig] = useState(null);
  const [appMeta, setAppMeta] = useState(null); //{room_id:null, event_id:null}
  const [updatedAt, setUpdatedAt] = useState(null);
  const [room, setRoom] = useState(null);
  const [amqpMsg, setAmqpMsg] = useState(null);

  useLayoutEffect(() => {
    const qsEventId = getEventId();
    const qsRoomId = getRoomId();
    let appMetaTypeObj = null;
    if (isNumber(qsEventId)) appMetaTypeObj = { event_id: qsEventId };
    else if (isNumber(qsRoomId)) appMetaTypeObj = { room_id: qsRoomId };
    if (appMetaTypeObj) setAppMeta(appMetaTypeObj);
  }, []);

  //NOTE: refresh/poll logic
  useLayoutEffect(() => {
    let timerRef = null;
    if (appMeta) {
      timerRef = setInterval(async() => {
        let respConfig = null;
        if (isNumber(appMeta?.event_id)) {
          respConfig = await get_airport_translation(appMeta?.event_id);
        } else {
          respConfig = await get_mlook_translation(appMeta?.room_id);
        }
        const translation = respConfig?.data?.[0]?.translationkey?.translations?.[0];
        const newUpdatedAt = translation?.updated_at || null;
        if(updatedAt !== newUpdatedAt){
          const appConfig = translation?.value ? JSON.parse(translation?.value):null;
          // const appConfig = JSON.parse(
          //   respConfig?.data?.[0]?.translationkey?.translations?.[0]?.value
          // );
          setUpdatedAt(newUpdatedAt);
          setAppConfig((prev)=> {
            const newConfig = _.cloneDeep({..._.merge(prev,appConfig)});
            if(newConfig?.reloadMode === "page")
              window.location.reload();//this is safe
             else{
              if (!isNullOrUndefined(newConfig?.component)) {
                newConfig.component.isDemo = getIsDemo()
                  ? true
                  : newConfig.component.isDemo;
                newConfig.component.base_api = getVmBaseUrl();
              }
             } 
            
            return newConfig;
          });
        }
      }, [120 * 1000]);
    }
    return () => clearInterval(timerRef);
  }, [appMeta, updatedAt]);

  useLayoutEffect(() => {
    if (!appMeta) return;
    (async () => {
      let lAppConfig = null;

      let respConfig = null;
      lAppConfig = mlookConfig;
      if (isNumber(appMeta?.event_id)) {
        lAppConfig = airportConfig;
        respConfig = await get_airport_translation(appMeta?.event_id);
      } else {
        respConfig = await get_mlook_translation(appMeta?.room_id);
        const roomCall = await get_room(appMeta?.room_id);
        if(roomCall?.ok)
          setRoom(roomCall?.data);

      }
      if (
        respConfig?.ok &&
        respConfig?.data?.[0]?.translationkey?.translations?.[0]?.value
      ) {
        const appConfig = JSON.parse(
          respConfig?.data?.[0]?.translationkey?.translations?.[0]?.value
        );
        lAppConfig = _.merge(lAppConfig, appConfig);
        setUpdatedAt(respConfig?.data?.[0]?.translationkey?.translations?.[0]?.updated_at)
      }
      if (!isNullOrUndefined(lAppConfig?.component)) {
        lAppConfig.component.isDemo = getIsDemo()
          ? true
          : lAppConfig.component.isDemo;
        lAppConfig.component.base_api = getVmBaseUrl();
      }
      if (lAppConfig?.cssSetting?.active) {
        await loadCss({
          src: lAppConfig?.cssSetting?.url,
          domId: "esignage-dynamic-css",
        });
      }

      if (lAppConfig) setAppConfig(lAppConfig);
      if (lAppConfig?.colors) setCssVariables(lAppConfig?.colors);
    })(); //IFII
  }, [appMeta]);

  let eventId = null;
  if(appMeta && appConfig?.activateVmSocket)
    eventId = appMeta?.room_id && room ? room?.event_id:appMeta.event_id;
  return (
    <SignageContext.Provider value={{ appConfig, appMeta, amqpMsg }}>
      {eventId && (
        <VmSocketContainer
          room_id={room?.id}
          socketUrl="wss://api.virtual-meeting.net/websocket"
          channels={[`event_${eventId}`]}
          onNewAmqpMsg={(amqpData)=>setAmqpMsg({...amqpData})}
        />
      )}
      {props.children}
    </SignageContext.Provider>
  );
};

export default SignageContext;
