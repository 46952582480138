import svg1 from "./../img/logoipsum-logo-8.svg";
const VM_BASE_API = "api-lb.virtual-meeting.net";

export const airportConfig = {
  component: {
    show: true,
    isDemo: false,
    timeZoneStr: "Europe/Berlin",
    //timeZoneStr: "America/New_York",
    base_api: VM_BASE_API,
    //base_api: "easd-api-lb.virtual-meeting.net",
    event_id: 153,
    //api_date: "2022-09-23", //format("YYYY-MM-DD")
    apiPoolIntervalSec: 70,
    pageSlideDurationSec: 30,
    pagingType: "bar",//"bar","number"
    tickerSettings: {
      active: true,
      messages: [
        "Come on baby",
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et lio",
      ],
    },
    twitterSettings: {
      active: true,
      commentsPerPage: 7,
      refreshTimeInSec: 60,
    },
    viewersSettings: {
      active: true,
      demo: false,
      txtViewers: "Online Viewer",
    },
    speakerSettings:{
      showCountryName : true, 
      showCity : false,
      showCoAuthors:true
    },
    sessionLegendSettings:{
      active:false,
      txtIndustry:"Industry",
      txtScientific:"Scientific"
    },
    footer: {
      show: true,
      formatDateTime: "HH:mm",
      formatDate: "LL", //DD-MM-YYYY
      formatLocale: "en", //de,fr,es
      logoLeftUrl:
        "https://d2t0unnjxes6lt.cloudfront.net/data/web/img/m-events-logo2022.png", //svg2,
      //logoLeftUrl: "https://pbs.twimg.com/profile_images/507459769162924032/QOyJkBXK_400x400.jpeg",
      logoRightUrl: svg1, //https://loremflickr.com/720/700
    },
    text: {
      //nothingToShow: "Nothing to show for the rest of day..",
      upNext:"UP NEXT: ",
      lblTime:"time",
      lblHall:"hall",
      lblSession:"session",
    },
  },
  reloadMode:"page",
  cssSetting:{
    active:false,
    url:"https://mevents-api-uploads.s3.amazonaws.com/api_uploads/mtv-master/dynamic-content/esmo/css/main_esmo.css"//=> no caching
  }
};

export const mlookConfig = {
  component: {
    show: true,
    isDemo: false,
    timeZoneStr: "Europe/Berlin",
    //standAloneMode:true,by default true
    //timeZoneStr: "America/New_York",
    base_api: VM_BASE_API,
    room_id: 4876, //2721,//5947,//5936,
    api_date: null, //"2022-11-05",//format("YYYY-MM-DD")
    apiPoolIntervalSec: 60,
    streamSettings: {
      active: false,
      type: "chime",
      //chimeLink:"https://demo.conference2web.com/faculty/mbox/2022/v2_27_v22/index.html?sid=%SID%&skip_gestures=1&bg_color=0e233a#/hd-designer"
      chimeLink:
        "https://demo.conference2web.com/faculty/mbox/2022/v2_27_v29/index.html?sid=%SID%&audio_off=1&skip_gestures=1&bg_color=0e233a#/hd-designer",
    },
    roomDetails: {
      active: false,
      durationSec: 15,
      pauseDurationSec: 60, //90
      showSpeakerImg: true,
      showChairs: true,
      chairSettings: {
        showCountryName: true,
        showCity: false,
        txtPrefixes: ["Chair", "Chairs"],
      },
      showCountdown: false,
      progressBarCss: "progress-bar-striped progress-bar-animated",
      qrCodeSettings: {
        active: true,
        baseUrl: "https://www.qna.at",
        height: 95,
        addEventId: true,
        addSessionId: false,
        //TODO: function Ref which will use Pako to decode
        //encodeFuncRef:(session)=>`https://www.tomo.at/session/${session?.id}`
        encodeFuncRef: null,
      },
    },
    viewersSettings: {
      active: true,
      demo: false,
      poolIntervalSec: 20,
      txtViewers: "Online Viewer",
    },
    countDownSettings: {
      text: { txtTimeElapsed: "", txtStartsIn: "Session starts in" },
      liveShowCurrentTime: true,
    },
    speakerSettings: {
      showCountryCode: true,
      showCountryName: true,
      showCity: false,
      showCoAuthors: true,
      coAuthorsCarouselIntervalSec: 5,
      txtCoAuthor: "Co-author:",
    },
    footer: {
      show: true,
      formatDateTime: "HH:mm",
      formatDate: "LL", //DD-MM-YYYY
      formatLocale: "en", //de,fr,es
      logoLeftUrl:
        "https://d2t0unnjxes6lt.cloudfront.net/data/web/img/m-events-logo2022.png", //svgDgn,//svg2,
      //logoRightUrl: "https://d2t0unnjxes6lt.cloudfront.net/data/dgn/data/assets/2022/Neurowoche-2022_Logo_white.png"//svg1, //https://loremflickr.com/720/700
      logoRightUrl: null,
    },
  },
  addsConfig:{
    active:false,
    className:"custom-css-class",
    blockAdds:[
      //"https://ia600300.us.archive.org/17/items/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4",
      "https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8",
      "https://secure.i.telegraph.co.uk/multimedia/archive/02540/Chris-Hadfield-ber_2540064b.jpg",
      "https://demo.conference2web.com/escrs/2018/top10/v1/"
    ],
    singleBlockItemDurationSec:5,
    timeDrivenMode: {
      active: false,
      startTime: "2023-06-07T15:00:00.000+0200",
      endTime: "2023-06-07T15:15:00.000+0200",
      displayTimeSec: 15,
      pauseTimeSec: 15,
    }
  },
  activateVmSocket: false,
  reloadMode:"page",
};
