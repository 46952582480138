import { getVmBaseUrl } from "./../utils/Helper";
import { makeHttpCall } from "./../utils/HttpHelpers";

export const get_translations = async (
  id,
  type = "Event",
  appKey = "signagev3.airport.config"
) => {
  const url = `https://${getVmBaseUrl()}/v1/translationkey_mappings.json`;
  let translatable_type = null;
  let translatable_id = null;

  if (id) {
    translatable_type = type;
    translatable_id = id;
  }
  if (!translatable_type)
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  const params = {
    translation_language: appKey,
    include_translations: 1,
    include_translatable: 1,
    translatable_type,
    translatable_id,
  };
  const result = await makeHttpCall({
    url: `${url}?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")}`,
    method: "GET",
    isVm: true,
  });
  return result;
};

export const get_airport_translation = async (id) => await get_translations(id);
export const get_mlook_translation = async (id) =>
  await get_translations(id, "Room", "signagev3.mlook.config");

export const get_room = async (room_id) => {
  const url = `https://${getVmBaseUrl()}/v1/rooms/${room_id}.json`;
  
  if (!room_id)
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  const params = {
  
  };
  const result = await makeHttpCall({
    url: `${url}?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")}`,
    method: "GET",
    isVm: true,
  });
  return result;
};
