import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SignageContextProvider } from "./store/esignage-store";

ReactDOM.render(
  <SignageContextProvider>
    <App />
  </SignageContextProvider>,
  document.getElementById("root")
);
