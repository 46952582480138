import "./LottieContainer.scss";
import React, { useLayoutEffect, useState, useRef } from "react";
import { loadScript } from "../utils/Tools";

const LOTTIE_JS_URL =
  "https://edge.conference2web.com/globals/bodymovin/5.9.1/lottie.min.js";
const LottieContainer = ({text}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const svgContainerRef = useRef(null);
  const bodymovinRef = useRef(null);

  const { bodymovin } = window;
  useLayoutEffect(() => {
    (async () => {
      if (!bodymovin) {
        await loadScript({ src: LOTTIE_JS_URL });
      }
      if (bodymovin) {
        bodymovinRef.current = bodymovin.loadAnimation({
          wrapper: svgContainerRef.current,
          animType: "svg",
          loop: true,
          destroy: () => console.log(`lottie onDestroy`),
          rendererSettings: {
            progressiveLoad:true,
            preserveAspectRatio:'xMidYMid meet'
        },
          //path: "https://labs.nearpod.com/bodymovin/demo/markus/isometric/markus2.json",
          path: 'https://edge.conference2web.com/globals/lottie/no_event_id.json'
        });
      }

      setScriptLoaded(true);
    })(); //IIFE

    //cleanup
    return () => {
      if (bodymovinRef.current) bodymovinRef.current?.destroy();
    };
  }, [bodymovin]);

  return (
    <div className="lottie-wrapper">
      <div className="lottie-wrapper__text">
        <i className="fas fa-exclamation-circle mr-2"></i>
        <span>{text}</span>
      </div>
      <div
        className="lottie-wrapper__svg-container"
        ref={svgContainerRef}
      ></div>
    </div>
  );
};
LottieContainer.defaultProps ={
    text:"You can't continue without eventid or roomid"
};
export default LottieContainer;
