import "./App.scss";
import React, { useContext } from "react";
import _ from "lodash";
import { MLook, Airport, Adds } from "react-vm-component-library";
import { isNumber } from "./utils/Validator";
import LottieContainer from "./components/LottieContainer";
import SignageContext from "./store/esignage-store";

function App() {
  //const [appConfig, setAppConfig] = useState(null);
  const { appConfig, appMeta, amqpMsg } = useContext(SignageContext);

  if (!appMeta) return <LottieContainer />;

  let jsx = null;
  if (appConfig) {
    if (isNumber(appMeta?.event_id))
      jsx = (
        <Airport {...appConfig?.component} event_id={parseInt(appMeta?.event_id)} />
      );
    else jsx = <MLook {...appConfig?.component} room_id={parseInt(appMeta?.room_id)} amqpObject={amqpMsg} />;
  } else {
    jsx = null;
  }


  return (
    <div className="mlook-app-container">
      {jsx}
      {appConfig?.addsConfig?.active && <Adds {...appConfig?.addsConfig}/>}
    </div>
  );
}

export default App;
