import { getSignalrUrl, getBaseUrl } from "./QueryString";


export const getQSSignalrUrl = () => {
  const qsSignalUrl = getSignalrUrl();
  if (qsSignalUrl) return decodeURIComponent(qsSignalUrl);

  return null;
};

export const getSignalrBaseUrl = () => {
  let qsSignalUrl = getQSSignalrUrl(),
    baseUrlSignalR = "https://signalr.m-events.com";
  //https://signalrtest.m-events.com
  if (qsSignalUrl) baseUrlSignalR = qsSignalUrl;
  return baseUrlSignalR;
};

export const getVmSocketBaseUrl = () => {
  return getVmBaseUrl();
};
export const getVmBaseUrl = () => {
  let baseUrl = "api-lb.virtual-meeting.net";
  const qsUrl = getBaseUrl();
  if (qsUrl) baseUrl = qsUrl;
  return baseUrl;
};
