import React from "react";
import useVmSocketRails from "../../hooks/useVmSocketRails";

const VmSocketContainer = ({
  socketUrl,
  channels,
  room_id,
  onNewAmqpMsg,
}) => {
  const onNewMessage = (data) => {
    if (typeof data == "string") {
      data = JSON.parse(data);
    }
    //console.log(data);
    const lRoomId = data?.content?.resource?.contentsession?.room?.id;
    console.log("lRoomId ",data?.content?.resource?.contentsession?.room?.title + " " + lRoomId)
    if (room_id === lRoomId) {
      console.log("hit", data?.content);
      if (typeof onNewAmqpMsg === "function") {
        const { AnimationNumber, Event, SlideNumber, resource, slide_url } = data?.content;
        onNewAmqpMsg({AnimationNumber, Event, SlideNumber, resource, slide_url});
      }
    }
  };
  const test = useVmSocketRails({
    socketUrl: socketUrl,
    channels: channels,
    onNewMessage,
  });

  return <></>;
};

export default VmSocketContainer;
