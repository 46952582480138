import { useState, useEffect, useCallback, useRef } from "react";
import { loadScript } from "../utils/Tools";


const RAILS_SOCKET_JS_URL =
  "https://edge.conference2web.com/globals/websocket/websocket_rails.0.0.1.min.js";
const useVmSocketRails = ({
  onNewMessage, 
  socketUrl="wss://api.virtual-meeting.net/websocket",
  channels,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [socketJsLoaded, setSocketJsLoaded] = useState(null);
  const [connectionAlive, setConnectionAlive] = useState(true);
  //const [isSocketLoaded, setIsSocketLoaded] = useState(false);
  const wsRef = useRef(null);

  //window.wsRef =wsRef;
  
  useEffect(() => {
    (async () => {
        if(typeof WebSocketRails === "function"){
            setSocketJsLoaded(true);
            return
        }
        await loadScript({ src: RAILS_SOCKET_JS_URL });
        setSocketJsLoaded(true);
      })();
  }, []);

  useEffect(() => {
    console.log("useEffect")
    const isSocketLoaded = typeof WebSocketRails === "function";
    let timer = null;
    timer = setInterval(()=>{
        console.log("timer " , wsRef.current?.state)
        if(wsRef.current?.state === "disconnected") _reconnect(true);
                
    }, 7 * 1000);
    const subscribeChannels = () => {
      //channels
      var _Channel = null;
      for (let currChannel of channels) {
        const gEventChannelId = currChannel;
        _Channel = wsRef?.current.subscribe(gEventChannelId);
        _Channel.on_failure = function (data) {
          console.warn(data);
        };
  
        _Channel.bind("channel_message", (data) => {
          wsRef.current.last_message_id = data.id;
          //console.log("channel_message:", data);
          if (typeof onNewMessage === "function") onNewMessage(data);
        });
        //_Channel.bind('channel_status', get_channel_status_message_success);
        wsRef?.current.trigger(
          "get_message",
          { channel_id: gEventChannelId },
          (data) =>{ 
            wsRef.current.last_message_id = data.id;
            //console.log("channel get_message_success:", data)
            if (typeof onNewMessage === "function") onNewMessage(data);
          },
          (data) => console.log("channel get_message_failure:", data)
        );
      }
     
    };
  
    const _reconnect = () => {
      console.log("reconnect");
      wsRef.current.last_pong = Date.now();
      wsRef.current.reconnect();
    };
   
    const ws_ConnClosed = (data) =>{
      console.warn("connection is closed");
      if (channels?.length) {
          for (let currChannel of channels) {
              wsRef.current.unsubscribe(currChannel);
          }
        }
    };
  
    const ws_ConnOpen = (data)=>{
      console.log("Connection has been established: ", data);
      subscribeChannels()
    };

    if(socketJsLoaded && socketUrl && channels?.length > 0 && isSocketLoaded){
        const {WebSocketRails} = window;

        const dispatcher = new WebSocketRails(socketUrl);
        dispatcher.on_open = ws_ConnOpen;
        dispatcher.bind("connection_closed", ws_ConnClosed);
        dispatcher.last_message_id = 0;
        dispatcher.last_pong = Date.now();
        dispatcher.pong = function () {
            setConnectionAlive(true);
            if(!channels?.length || channels?.length <= 0)return;

            this.last_pong = Date.now();
            var pong, _ref;
            pong = new WebSocketRails.Event([
              "websocket_rails.pong",
              { channel_id: channels[0], message_id: this.last_message_id },
              (_ref = this._conn) != null ? _ref.connection_id : void 0,
            ]);
            return this._conn.trigger(pong);
        };
        
        dispatcher.bind("dispatcher_message", (data)=>console.log("dispatcher_message:"));
        wsRef.current = dispatcher;
       
    }        
    return () => {
      clearInterval(timer);
      let dispatcher = wsRef.current;
      if (!dispatcher) return;
      if (!isSocketLoaded) return;
      if (socketJsLoaded && socketUrl && channels?.length > 0 && isSocketLoaded) {
        dispatcher.last_message_id = 0;
        dispatcher.disconnect();
      }
    };    
  }, [/*socketUrl, channels,*/socketJsLoaded]);

  return { ws:wsRef.current, connectionAlive };
};

export default useVmSocketRails;
