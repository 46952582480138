import { isNullOrUndefined } from "./Validator";
import _ from "lodash";
import { inflate64 } from './PakoHelper';

let finalTokenData = null;
let inflateStr = null;
const getFromToken = (pQsKey) => {
  try {
    if (!finalTokenData) {
      const qsToken = getToken();
      if (!qsToken) return null;
      //console.time("sd")
      let tokenObj = inflateStr ? inflateStr : inflate64(qsToken);
      if (!inflateStr) inflateStr = tokenObj; //remember token to speed up a littles

      if (_.isString(tokenObj)) {
        tokenObj = JSON.parse(tokenObj);
        //Translate new to old Pramerter Names
        // if(tokenObj.VmSessionId)
        //     tokenObj.sid = tokenObj.VmSessionId;
        // if(tokenObj.sid)
        //     tokenObj.s_id = tokenObj.sid;
        Object.entries(tokenObj).forEach(([key, value]) => {
          tokenObj[key.toLowerCase()] = value;
        });
      }
      //console.timeEnd("sd")
      if (!_.isObject(tokenObj)) return null;

      finalTokenData = tokenObj;
    }

    let lValue = finalTokenData[pQsKey];
    return lValue ? lValue : null;
  } catch (err) {
    console.log(err);
  }
  return null;
};
window.getFromToken = getFromToken;


const getQSValue = (pQsKey) => {
  if (isNullOrUndefined(pQsKey)) return null;
  const lQsKey = pQsKey.toLowerCase();
  const lUrlParams = getUrlParamsCaseIS(); //NOTE: make it case insensitive
  let lQsValue = lUrlParams.get(lQsKey);

  let isEncoded = false;
   //3rd fallback check token
   if(!lQsValue){
    lQsValue = getFromToken(lQsKey);
    isEncoded = false;
}

  return lQsValue;
};
//NOTE: make it case insensitive
const getUrlParamsCaseIS = () => {
  const qsParams = new URLSearchParams(window.location.search);
  let lUrlParams = new URLSearchParams();
  for (const [name, value] of qsParams) {
    lUrlParams.append(name.toLowerCase(), value);
  }
  return lUrlParams;
};




export const getToken = () => {
  const urlParams = getUrlParamsCaseIS();
  const qsToken = urlParams.get('token');
  return qsToken ? qsToken : null;
}
export const getBaseUrl = () => {
  //const urlParams = new URLSearchParams(window.location.search);
  return getQSValue("base_url");
};

export const getEventId = () => getQSValue("event_id");
export const getRoomId = () => getQSValue("room_id");
export const getSessionId = () => getQSValue("session_id");

export const getIsDemo = () => getQSValue("is_demo") == "1" ? true:false ;

export const getSignalrUrl = () => getQSValue("signalr_url");

