/* eslint-disable no-throw-literal */
/* eslint-disable no-useless-escape */

import { isNullOrWhitespace } from "./Validator";

export const getURLParamsObj = function () {
  var params = {};
  var currentURL = window.location.href,
    hash = window.location.hash;
  if (hash.length > 0) currentURL = currentURL.replace(hash, "");
  var paramArray = currentURL.split("?");
  if (paramArray.length === 2) {
    var tmpArray = paramArray[1].split("&");
    for (var i = 0; i < tmpArray.length; i++) {
      var param = tmpArray[i];
      var array = param.split("=");
      if (array[1] !== "*") {
        params[array[0]] = array[1];
      }
    }
  }
  return params;
};

export const getRandomNumber = () => {
  const min = 1;
  const max = 1000000000;
  const rand = min + Math.random() * (max - min);
  return Math.floor(rand);
};

export const isHttp = () => {
  try {
    const { location } = window;
    if (location.hostname === "127.0.0.1" || location.hostname === "localhost")
      return false;
    if (location.protocol !== "https:") return true;
  } catch (err) {
    console.log(err);
  }
  return false;
};

//await delay(5000);
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const loadScript = async (
  { src, integrity, crossOrigin },
  timeoutMs = 7000
) => {
  // the tf library loading order must be followed

  await new Promise((resolve, reject) => {
    //const  head = document.getElementsByTagName('head')[0];
    const script = document.createElement("script");
    const timer = setTimeout(() => {
      reject(new Error(`Loading script ${src} takes longer than ${timeoutMs}`));
    }, timeoutMs);
    script.onload = function (_ev) {
      clearTimeout(timer);
      resolve();
    };
    script.onerror = function (_ev) {
      clearTimeout(timer);
      reject(new Error(`Failed to load ${src}`));
    };
    if (integrity) script.integrity = integrity;
    if (crossOrigin) script.crossOrigin = crossOrigin;
    script.src = src;
    document.body.appendChild(script);
  });
};

export const loadCss = async (
  {src, domId},
  timeoutMs = 7000
) => {
  // the tf library loading order must be followed
  if(document.getElementById(domId) || !domId){
    return new Promise((resolve, reject) =>   reject(new Error(`css file with id=${domId} and src= ${src} already exists`)))
  }

  await new Promise((resolve, reject) => {
    const  head = document.getElementsByTagName('head')[0];
    const fileRef = document.createElement("link");
    const timer = setTimeout(() => {
      reject(new Error(`Loading css ${src} takes longer than ${timeoutMs}`));
    }, timeoutMs);
    fileRef.onload = function (_ev) {
      clearTimeout(timer);
      resolve();
    };
    fileRef.onerror = function (_ev) {
      clearTimeout(timer);
      reject(new Error(`Failed to load ${src}`));
    };
    
    fileRef.href  = src;
    fileRef.id  = domId;
    fileRef.rel = "stylesheet";
    fileRef.type = "text/css";
    head.appendChild(fileRef);
  });
};

export const formatString =  (pStringTemplate, ...args) =>{
  var args = args;
  return pStringTemplate.replace(/{([0-9]+)}/g, function (match, index) {
    // check if the argument is there
    return typeof args[index] == 'undefined' ? match : args[index];
  });
};

export const getTimeRemaining = (totalInSec)=>{
  //const total = Date.parse(endtime) - Date.parse(new Date());
  const total = totalInSec;
  let mathFunc = Math.floor;
  if(totalInSec < 0)
    mathFunc = Math.ceil;
  const seconds = mathFunc( (total) % 60 );
  const minutes = mathFunc( (total/60) % 60 );
  const hours = mathFunc( (total/(60*60)) % 24 );
  const days = mathFunc( total/(60*60*24) );

  return {
    total,
    days,
    hours,
    minutes,
    seconds
  };
};

export const padLeft = (value) => ("0" + value).slice(-2);

export const convertRemToPixels=(rem)=> {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
window.convertRemToPixels=convertRemToPixels;

export const setCssVariable=(variableName, variableValue)=> {
  try{
    var r = document.querySelector(':root');
    r.style.setProperty(variableName, variableValue);
    //.setProperty('--my-variable-name', 'pink');
  }catch(err){console.log(err);}
}

export const getCssVariable=(variableName)=> {
  try{
    window.getComputedStyle(document.documentElement).getPropertyValue(variableName);
  }catch(err){console.log(err);}
}

/**
 * object in a form {cssVar1Name:cssVar1Value}
 * @param {*} cssVariables 
 */
 export const setCssVariables =(cssVariables)=>{
  try {
    var r = document.querySelector(":root");
    let keys = Object.keys(cssVariables);
    keys.map((c) => {
      r.style.setProperty(c, cssVariables[c]);
      return true;
    });
  } catch (err) {
    console.log(err);
  }
}
export const toApiParams =(pDictionary)=>Object.keys(pDictionary).map(c=>`${c}=${pDictionary[c]}`).join('&');


export const hexToRgb = (hex) => hex
  .replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => `#${r + r + g + g + b + b}`,
  )
  .substring(1)
  .match(/.{2}/g)
  .map((x) => parseInt(x, 16));

export const getContrastColor= function(hex) {
  var o, rgb;
  rgb = hexToRgb(hex);
  o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);
  if (o > 125) {
      return 'black';
  } else {
      return 'white';
  }
};

export const isElementInViewport = (el, percentage = 50, ignoreTop=true) => {
  const { innerHeight, innerWidth } = window; // eslint-disable-line
  const { clientHeight, clientWidth, scrollTop } = document.documentElement; // eslint-disable-line

  const rect = el.getBoundingClientRect();

  const offScreenTop = 0 - (rect.height * percentage) / 100;
  if (scrollTop < el?.offsetTop && ignoreTop) {//NOTE: ignore if videoEl is bellow scroll
    return true;
  }
  return (
    rect.top >= offScreenTop
    && rect.left >= 0
    && rect.bottom <= (innerHeight || clientHeight)
    && rect.right <= (innerWidth || clientWidth)
  );
};

export const isOverflown = (element)=> {
  if(element)
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  return false;  
}


export const addToQueryStringParams = (pLink, paramaName, paramValue)=> {
  //const parsedUrl = new URL("https://demo.conference2web.com/faculty/mbox/2022/v2_27_v22/index.html?sid=44444&audio_off=1&skip_gestures=1&bg_color=0e233a#/hd-designer&0.36443567337908656");
  const parsedUrl = new URL(pLink);
  const search = !isNullOrWhitespace(parsedUrl?.search) ? `${parsedUrl?.search}&${paramaName}=${paramValue}` : `?${paramaName}=${paramValue}`;

  return `${parsedUrl.origin}${parsedUrl.pathname}${search}${parsedUrl.hash}`;
}
